import React, { useCallback, useContext, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { AppContext } from '../Context';
import './step-style-form.css';
import occupationData from '../../../constantData.json';
import PlacesAutocomplete from '../../extra-components/placesAutocomplete';
import { Box } from '@mui/material';

export default function SecondStep() {
  const { formValues, handleChange, handleBack, handleNext, variant, margin } = useContext(AppContext);
  const { jobType, jobAddress, companyName, jobDesignation, field, degree, college, passingYear, salary } = formValues;

  // const isError = useCallback(
  //   () =>
  //     Object.keys({ city, date, phone, agreenemt }).some(
  //       (name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
  //     ),
  //   [formValues, city, date, phone, agreenemt]
  // )

  return (
    <div className="wrapper">
      <div className="step-header">Education details</div>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Box sx={{ width: '100%' }}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Degree/Class"
            name="degree"
            placeholder="Enter your Degree/Class"
            value={degree.value}
            onChange={handleChange}
            error={!!degree.error}
            helperText={degree.error}
            // required={degree.required}
          />
        </Box>
        <Box sx={{ width: '100%' }}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="College/School"
            name="college"
            placeholder="Enter your College/School Name"
            value={college.value}
            onChange={handleChange}
            helperText={college.error}
            // required={college.required}
          />
        </Box>

        <Box sx={{ width: '100%' }}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Field"
            name="field"
            placeholder="Enter your Field of Education"
            value={field.value}
            onChange={handleChange}
            // error={!!city.error}
            // helperText={city.error}
            // required={city.required}
          />
        </Box>

        <Box sx={{ width: '100%' }}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Passing Year"
            name="passingYear"
            placeholder="Enter your Passing Year"
            value={passingYear.value}
            onChange={handleChange}
            helperText={passingYear.error}
          />
        </Box>

        <div className="step-header">Occupation details</div>

        <Box sx={{ width: '100%' }}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            select
            label="Job Type"
            SelectProps={{
              native: true,
            }}
            name="jobType"
            value={jobType.value}
            onChange={handleChange}
            // error={!!jobType.error}
            // helperText={jobType.error}
            // required={jobType.required}
          >
            {occupationData.OCCUPATION.values.map((option) => (
              <option key={option.value} value={option.value}>
                {option.display}
              </option>
            ))}
          </TextField>
        </Box>

        {/* {jobType.value !== 'FARMER' && (
          <> */}
        <Box sx={{ width: '100%' }}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Job Designation"
            name="jobDesignation"
            placeholder="Enter your Job Designation"
            value={jobDesignation.value}
            onChange={handleChange}
            error={!!jobDesignation.error}
            helperText={jobDesignation.error}
            required={jobDesignation.required}
            disabled={jobType.value === 'FARMER' || jobType.value === ' '}
          />
        </Box>

        <Box sx={{ width: '100%' }}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Company Name"
            name="companyName"
            placeholder="Company Name"
            value={companyName.value}
            onChange={handleChange}
            error={!!companyName.error}
            helperText={companyName.error}
            required={companyName.required}
            disabled={jobType.value === 'FARMER' || jobType.value === ' '}
          />
        </Box>
        {/* </>
        )} */}

        <Box sx={{ width: '100%' }}>
          {/* <TextField
          variant={variant}
          margin={margin}
          fullWidth
          label="Location"
          name="jobAddress"
          placeholder="Enter your Work Location"
          value={jobAddress.value}
          onChange={handleChange}
          error={!!jobAddress.error}
          helperText={jobAddress.error}
          required={jobAddress.required}
          disabled={jobType.value === ' '}
        /> */}
          <PlacesAutocomplete
            type="jobAddress"
            label="Enter your work location"
            placeAddressObject={{
              ...jobAddress?.value,
              formatted_address: jobAddress?.value?.formatted_address || jobAddress?.value,
            }}
          />
        </Box>

        <Box sx={{ width: '100%' }}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label="Salary(in LPA)"
            name="salary"
            placeholder="Salary(in LPA)"
            value={salary.value}
            onChange={handleChange}
          />
        </Box>

        {/* <Grid item xs={12}>
          <TextField
            variant={variant}
            margin={margin}
            fullWidth
            label='Phone number'
            name='phone'
            placeholder='i.e: xxx-xxx-xxxx'
            value={phone.value}
            onChange={handleChange}
            error={!!phone.error}
            helperText={phone.error}
            required={phone.required}
          />
        </Grid> */}
        {/* <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={agreenemt.value}
                onChange={handleChange}
                name='agreenemt'
                color='primary'
                required={agreenemt.required}
              />
            }
            label='Agree to terms and conditions'
          />
          <FormHelperText error={!!agreenemt.error}>{agreenemt.error}</FormHelperText>
        </Grid> */}
      </Box>

      {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
        <Button onClick={handleBack} sx={{ mr: 1 }}>
          Back
        </Button>
        <Button variant='contained' disabled={isError()} color='primary' onClick={!isError() ? handleNext : () => null}>
          Next
        </Button>
      </Box> */}
    </div>
  );
}
